import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMiniSearch } from 'react-minisearch';
// import Container from 'react-bootstrap/Container';

import Spinner from './Spinner';
import useFetchAndProcessData from '../datacalls/useFetchAndProcessData';


const miniSearchOptions = ({
  fields: ['title', 'short_blurb'],
  storeFields: ['title', 'slug', 'short_blurb'],
  searchOptions: {
    boost: { title: 2 },
    fuzzy: 0.2
  }
});

const Search = ({ documents }) => {
  const { search, searchResults } = useMiniSearch(documents, miniSearchOptions);
  const [query, setQuery] = useState('');
  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    search(newQuery);
  };
  return (
    <div>
      <input
        type='text'
        onChange={handleSearchChange}
        value={query}
        placeholder='Search . . .'
      />
      <br />
      <br />
      {query && ( // Only render if there's a query
        <>
          <h3>Search Results:</h3>
          <ul>
            {searchResults && searchResults.length > 0 ? (
              searchResults.map((result, i) => (
                <li key={i}>
                  <Link to={`/books/${result.slug}`} className="link-router">
                    {result.title}
                  </Link>
                </li>
              ))
            ) : (
              <p>No Results Found</p>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

const SearchContainer = () => {
  const { documents, loading, error } = useFetchAndProcessData();

  if (loading) return <div align="center"><Spinner /></div>;
  if (error) return <div align="center">An error has occurred: {error.message}</div>;

  return <Search documents={documents} />;
};

export default SearchContainer;
