
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';
import Row from 'react-bootstrap/Row';
import { FaExternalLinkAlt } from 'react-icons/fa';

import Spinner from '../components/Spinner';
import { getContents, getContent } from '../datacalls/contentQuery';

/*
This item is a card for listing multiple books in an area.

 className={`container-${book.page_background_type}`} fluid

<p>Paperback Price: <a href={book.amazon_paperback}>${numberWithCommas(Number(book.paperback_price))}</a>
| Kindle Price: <a href={book.amazon_kindle}>${numberWithCommas(Number(book.kindle_price))}</a></p>
*/
const BookCard = ({book}) => {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

    // <p>This book is part of the {book.series} series.</p>
  if (book.series !== "Standalone" && book.amazon_paperback !== "https://nopaperback.com") {
    return (
      <div>
        <Card className={`card-${book.page_background_type}`}>
          <Container fluid>
            <Row>
              <Col sm={4}>
                <Figure>
                  <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src={book.cover_photo}
                  />
                </Figure>
              </Col>

              <Col sm={8}>
                <Row>
                  <div className={`title-${book.page_background_type}`} >{book.title}</div>
                  <p>This book is part of the {book.series} series.</p>
                  <p>{book.description.substring(0,200)}...</p>
                </Row>
                <Row>
                  <br />
                  <Link to={`/books/${book.slug}`}>
                    <button className={`neon-animated-button-${book.page_background_type}`} >For More Book Information</button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Container>

          <br />

          <Container>
            <Row>
              <Col>
                <Row>
                  <p></p>
                  <hr />
                  <p>The book is available on Amazon in paperback and Kindle edition, and you can check them out using the following links:</p>
                  <p>
                    <a
                      href={book.amazon_paperback}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Paperback: ${book.paperback_price} <FaExternalLinkAlt />
                    </a>
                      {"  "}|{"  "}
                    <a
                      href={book.amazon_kindle}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kindle: ${book.kindle_price} <FaExternalLinkAlt />
                    </a>
                  </p>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  } else if (book.series === "Standalone" && book.amazon_paperback !== "https://nopaperback.com") {

    return (
      <div>
        <Card className={`card-${book.page_background_type}`}>
          <Container fluid>
            <Row>
              <Col sm={4}>
                <Figure>
                  <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src={book.cover_photo}
                  />
                </Figure>
              </Col>

              <Col sm={8}>
                <Row>
                  <div className={`title-${book.page_background_type}`} >{book.title}</div>
                  <p>The book is a standalone story.</p>
                  <p>{book.description.substring(0,200)}...</p>
                </Row>
                <Row>
                  <br />
                  <Link to={`/books/${book.slug}`}>
                    <button className={`neon-animated-button-${book.page_background_type}`} >For More Book Information</button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Container>

          <br />

          <Container>
            <Row>
              <Col>
                <Row>
                  <p></p>
                  <hr />
                  <p>The book is available on Amazon in paperback and Kindle edition, and you can check them out using the following links:</p>
                  <p>
                    <a
                      href={book.amazon_paperback}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Paperback: ${book.paperback_price} <FaExternalLinkAlt />
                    </a>
                      {"  "}|{"  "}
                    <a
                      href={book.amazon_kindle}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kindle: ${book.kindle_price} <FaExternalLinkAlt />
                    </a>
                  </p>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  } else if (book.series !== "Standalone" && book.amazon_paperback === "https://nopaperback.com") {
    return (
      <div>
        <Card className={`card-${book.page_background_type}`}>
          <Container fluid>
            <Row>
              <Col sm={4}>
                <Figure>
                  <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src={book.cover_photo}
                  />
                </Figure>
              </Col>

              <Col sm={8}>
                <Row>
                  <div className={`title-${book.page_background_type}`} >{book.title}</div>
                  <p>This book is part of the {book.series} series.</p>
                  <p>{book.description.substring(0,200)}...</p>
                </Row>
                <Row>
                  <br />
                  <Link to={`/books/${book.slug}`}>
                    <button className={`neon-animated-button-${book.page_background_type}`} >For More Book Information</button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Container>

          <br />

          <Container>
            <Row>
              <Col>
                <Row>
                  <p></p>
                  <hr />
                  <p>The book is available on Amazon on Kindle, and you can check it out using the following link:</p>
                  <p>
                    <a
                      href={book.amazon_kindle}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                        Kindle: ${book.kindle_price} <FaExternalLinkAlt />
                    </a>
                  </p>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  } else {
    return (
      <div>
        <Card className={`card-${book.page_background_type}`}>
          <Container fluid>
            <Row>
              <Col sm={4}>
                <Figure>
                  <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src={book.cover_photo}
                  />
                </Figure>
              </Col>

              <Col sm={8}>
                <Row>
                  <div className={`title-${book.page_background_type}`} >{book.title}</div>
                  <p>The book is a standalone story.</p>
                  <p>{book.description.substring(0,200)}...</p>
                </Row>
                <Row>
                  <br />
                  <Link to={`/books/${book.slug}`}>
                    <button className={`neon-animated-button-${book.page_background_type}`} >For More Book Information</button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Container>

          <br />

          <Container>
            <Row>
              <Col>
                <Row>
                  <p></p>
                  <hr />
                  <p>The book is available on Amazon on Kindle, and you can check it out using the following link:</p>
                  <p>
                    <a
                      href={book.amazon_kindle}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kindle: ${book.kindle_price} <FaExternalLinkAlt />
                    </a>
                  </p>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  }
};

export default BookCard;
