
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { getContents, getContent } from '../datacalls/contentQuery';
import ArmanisArFeinialAuthorsPhoto from '../assets/ArmanisArFeinialAuthorsPhoto.jpg';
import Spinner from '../components/Spinner';
// AUTHOR_DESCRIPTION = 'author-description'


export default function AuthorDescription(props) {
    const queryClient = useQueryClient();
    const [selectedContent, setSelectedContent] = useState();
    // from the model AUTHOR_DESCRIPTION = 'author-description'
    const { isPending, isError, data, error } = useQuery({
      queryKey: ['author', selectedContent],
      queryFn: () => getContent('author-description'), // using the api slug here
    });
    if (isPending) {
      return <div align="center"><Spinner /></div>;
    }
    if (isError) {
      return <p>Error from the Author Description section: {error.message}</p>;
    }
    let author = data;

    return (
        <>
          <ReactMarkdown>{author.description}</ReactMarkdown>
        </>
    );
}
