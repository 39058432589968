
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
// import ReactMarkdown from 'react-markdown';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';
import Row from 'react-bootstrap/Row';

import AboutMe from '../sitecontents/AboutMe';
import Spinner from '../components/Spinner';
import Title from '../components/Title';
import { getContents, getContent } from '../datacalls/contentQuery';

import BookOnTable from '../assets/BookOnTable.jpg';


export default function About(props) {

    return (
      <>
      <Title
        title="Armanis Ar-feinial | About"
        description="The about page on Armanis Ar-Feinial's Website"
        keywords="book series, fantasy book series, armanis ar-feinial, fantasy author, grim dark author, the Hedgehog, the Falling"
      />
        <h1>All About Me and the Site</h1>
        <Card className="card-blue-green">
          <Card.Title>
            <Row>
              <Col align="center">
                <Figure>
                  <Figure.Image
                    width={600}
                    alt="300 as width"
                    src={BookOnTable}
                  />
                  <Figure.Caption className="figure-caption-custom">
                    The Tedward is a book by Armanis Ar-feinial
                  </Figure.Caption>
                </Figure>
              </Col>
            </Row>
          </Card.Title>
          <Card.Body>
            <AboutMe />
          </Card.Body>
        </Card>
      </>
    );
}

// <h3>Selected Book</h3>
// {bookQuery.isLoading ? (
//   <Spinner />
// ) : (
//   <>
//     <pre>{JSON.stringify(bookQuery.data, null, 2)}</pre>
//   </>
//
// )}
