import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import { SiX, SiAmazon, SiFacebook, SiGoodreads } from "react-icons/si";

// Removed SiTwitter for X

const Footer = () => {
  return (
    <Container fluid className="page-bottom">
      <Row>
        <Col>
          <a
            href="https://www.amazon.com/kindle-dbs/entity/author/B086R9T1CS?_encoding=UTF8&node=2656022011&offset=0&pageSize=12&searchAlias=stripbooks&sort=author-sidecar-rank&page=1&langFilter=default#formatSelectorHeader"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              {" "}
              <SiAmazon className="footer-icon" />{" "}
            </span>
          </a>
          <span>{"   "}</span>
          <a
            href="https://twitter.com/sarcastic_elf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              {"  "}
              <SiX className="footer-icon" />
              {"  "}
            </span>
          </a>
          <span>{"   "}</span>
          <a
            href="https://www.facebook.com/ArmanisArfeinial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              {"  "}
              <SiFacebook className="footer-icon" />
              {"  "}
            </span>
          </a>
          <span>{"   "}</span>
          <a
            href="https://www.goodreads.com/author/show/8313301.Armanis_Ar_Feinial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <SiGoodreads className="footer-icon" />
            </span>
          </a>
          <span>{"   "}</span>
          <span className="footer-glow">
            Armanis Ar-feinial {new Date().getFullYear()}
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
