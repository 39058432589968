
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { getContents, getContent } from '../datacalls/contentQuery';
import Spinner from '../components/Spinner';


export default function Welcome(props) {
    const queryClient = useQueryClient();
    const [selectedContent, setSelectedContent] = useState();
    // from the model WELCOME_TEXT = 'front-page-welcome'
    const { isPending, isError, data, error } = useQuery({
      queryKey: ['welcome', selectedContent],
      queryFn: () => getContent('front-page-welcome'), // using the api slug here
    });
    if (isPending) {
      return <div align="center"><Spinner /></div>;
    }
    if (isError) {
      return <p>Error from the Welcome section: {error.message}</p>;
    }
    let welcome = data;

    return (
      <>
        <div>
          <h1>Welcome</h1>
          <ReactMarkdown>{welcome.description}</ReactMarkdown>
        </div>
      </>
    );
}
