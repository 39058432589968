import axios from 'axios';
import { useQuery } from '@tanstack/react-query';


async function setupNetwork() {
  return new Promise((res) => {
    setTimeout(res, Math.random() * 800);
  });
}

export async function getContents() {
    const apiUrl = process.env.BACK_TEST_URL
    const { data } = await axios.get(`${apiUrl}/contentapi/`);
    let contents = data;
    // console.log("From the contents query", contents);
    return contents;
}

export async function getContent(selectedContent) {
    const apiUrl = process.env.BACK_TEST_URL
    const { data } = await axios.get(`${apiUrl}/contentapi/${selectedContent}`)
    let content = data;
    // console.log("From the content query", content);
    return content;
}
