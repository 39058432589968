import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Outlet,
  useLoaderData,
  useParams,
  Form,
  Link,
  useNavigation,
  useSubmit,
  redirect,
  // ScrollRestoration,
} from 'react-router-dom';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadBasic } from '@tsparticles/basic';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { GiHouse } from 'react-icons/gi';
import { TiThMenuOutline } from 'react-icons/ti';

import Footer from '../components/Footer';
import Spinner from '../components/Spinner';
import navicon from '../assets/navicon.png';
// import PossibleGrimDark from '../assets/PossibleGrimDark.png';
import { getBooks } from '../datacalls/bookQuery';

// The nullish coalescing operator ( ?? ) is a logical operator that returns
// its right-hand side operand when its left-hand side operand is null
// or undefined , and otherwise returns its left-hand side operand.
// const bookListQuery = (q) => ({
//   queryKey: [q ?? "all"],
//   queryFn: () => getBooks(q),
// });
// className={({ isActive, isPending }) =>
//   isActive ? "active" : isPending ? "pending" : ""
// }
// to={`/${bookId}`}
// <Nav.Link href="/books">Books</Nav.Link>

export default function Root() {
    const navigation = useNavigation();
    const [init, setInit] = useState(false);

    useEffect(() => {
      initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadBasic(engine);
      }).then(() => {
        setInit(true);
      });
    }, []);

    const particlesLoaded = (container) => {
      console.log(container);
    };

    const options = useMemo(
      () => ({
        particles: {
          number: {
            value: 300,
            density: {
              enable: true,
              area: 800
            }
          },
          color: {
            value: ["#d1ffe2", "#e0e0e0", "#1be081", "#92a7a9"]
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000"
            },
            polygon: {
              nb_sides: 5
            }
          },
          opacity: {
            value: 0.2,
            random: false
          },
          size: {
            value: { min: 1, max: 3 }
          },
          move: {
            enable: true,
            speed: 0.2,
            direction: "none",
            random: false,
            straight: false,
            outModes: "out"
          },
        },
        detectRetina: true,
      }),
      [],
    );

    const particlesInit = useCallback(async (engine) => {
      await loadFull(engine);
    }, []);

    // console.log("seeing if this runs more than once, I'm in root BEFORE calling getBooks");
    const {
      status,
      data,
      error,
    } = useQuery({
      queryKey: ['books'],
      queryFn: getBooks,
    });

    if (status === 'pending') {
      // console.log("status is pending");
      return <div align="center"><Spinner /></div>;
    }
    if (status === 'error') {
      // console.log("status is error");
      return <p>Error from the Root: {error.message}</p>;
    }
    if (status === 'success') {
      console.log("success");
    }
    // console.log("The getBooks on the root page should have just run here");
    // console.log("The following is data");
    // console.log(data);
    let books = data;
    // const {
    //   isPending: booksLoading,
    //   isError: booksError,
    //   status,
    //   data: books,
    //   error: booksErrorDetails,
    // } = useQuery({
    //   queryKey: ['books'],
    //   queryFn: () => getBooks,
    // });
    // const { isPending, isError, data, error } = useQuery(['books'], getBooks);
    return (
      <>
        <Navbar collapseOnSelect expand="lg" fixed="top" className="navbar-top">
          <Container fluid>
            <Navbar.Brand href="/home">
              <img
                alt="Armanis Ar-feinial navicon"
                src={navicon}
                width="30"
                height="30"
                className="d-inline-block align-bottom"
              />{' '}
                Armanis Ar-feinial
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="offcanvasNavbar-expand-lg"
            >
              <span><TiThMenuOutline className="menu-outline" /></span>
            </Navbar.Toggle>
            <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
              <Nav.Link href="/books">Book List</Nav.Link>
              <NavDropdown
                align={{ lg: 'end' }}
                title="The Books!"
                id="dropdown-menu-align-responsive-1"
              >
              {status === 'pending' ? (
                <Spinner />
              ) : status === 'success' ? (
                books.results.map((book) => {
                  const bookId = book.slug;
                  return (
                    <NavDropdown.Item key={book.pkid}>
                      <Link
                        to={`/books/${bookId}`}
                        className={({ isActive, isPending }) =>
                          isActive ? "active" : isPending ? "pending" : ""
                        }
                      >
                        {book.title}
                      </Link>
                    </NavDropdown.Item>
                  );
                })
              ) : status === 'error' ? (
                <p>Error from the all Books page: {error.message}</p>
              ) : null}
              </NavDropdown>
            </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container className="page-container">
          <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />
          <Outlet />
        </Container>
      <Footer />
    </>
  );
}

// {Array.isArray(data) && data.length > 0 ? (
//   data.results.map((book) => {
//     const bookId = book.slug;
//     return (
//       <NavDropdown.Item key={book.pkid}>
//         <Link
//           to={`/books/${bookId}`}
//           className={({ isActive, isPending }) =>
//             isActive ? "active" : isPending ? "pending" : ""
//           }
//         >
//           {book.title}
//         </Link>
//       </NavDropdown.Item>
//     );
//   })
// ) : (
//   <p>No books available</p>
// )}
// {data.results.map((book) => {
//   const bookId = book.slug;
//   return (
//     <NavDropdown.Item key={book.pkid}>
//       <Link
//         to={`/books/${bookId}`}
//         className={({ isActive, isPending }) =>
//           isActive ? "active" : isPending ? "pending" : ""
//         }
//       >
//         {book.title}
//       </Link>
//     </NavDropdown.Item>
//     );
//   })}
// {Array.isArray(data) && data.length > 0 ? (
//   data.results.map((book) => {
//     const bookId = book.slug;
//     return (
//       <NavDropdown.Item key={book.pkid}>
//         <Link
//           to={`/books/${bookId}`}
//           className={({ isActive, isPending }) =>
//             isActive ? "active" : isPending ? "pending" : ""
//           }
//         >
//           {book.title}
//         </Link>
//       </NavDropdown.Item>
//     );
//   })
// ) : (
//   <p>No books available</p>
// )}
