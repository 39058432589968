
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { getContents, getContent } from '../datacalls/contentQuery';
import Spinner from '../components/Spinner';


export default function AboutMe(props) {
    const queryClient = useQueryClient();
    const [selectedContent, setSelectedContent] = useState();
    // from the model ABOUT_THE_SITE = 'about-me'
    const { isPending, isError, data, error } = useQuery({
      queryKey: ['about-me', selectedContent],
      queryFn: () => getContent('about-me'), // using the api slug here
    });
    if (isPending) {
      return <div align="center"><Spinner /></div>;
    }
    if (isError) {
      return <p>Error from the AboutMe section: {error.message}</p>;
    }
    let aboutme = data;

    return (
      <>
        <ReactMarkdown>{aboutme.description}</ReactMarkdown>
      </>
    );
}
