
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';
import Row from 'react-bootstrap/Row';

// import Spinner from '../components/Spinner';
import Title from '../components/Title';
import { getContents, getContent } from '../datacalls/contentQuery';
import { getBooks } from '../datacalls/bookQuery';
import Announcements from '../sitecontents/Announcements';
import AuthorDescription from '../sitecontents/AuthorDescription';
// import BookCard from '../books/BookCard';
import Welcome from '../sitecontents/Welcome';
import ArmanisArFeinialAuthorsPhoto from '../assets/ArmanisArFeinialAuthorsPhoto.jpg';

import { GiArchaeopteryxFossil } from 'react-icons/gi';


export default function Home(props) {
    const { isPending, isError, data, error } = useQuery({
      queryKey: ['books'],
      queryFn: getBooks,
    });
    if (isPending) {
      return <div align="center"><Spinner /></div>;
    }
    if (isError) {
      return <p>Error from the Books page: {error.message}</p>;
    }

    return (
      <>
      <Title
        title="Armanis Ar-feinial | Home"
        description="The home page for the Armanis Ar-Feinial's website"
        keywords="armanis ar-feinial, author, fantasy author, grim dark author, self published author, the Hedgehog, the Falling"
      />
      <p></p>
        <Row>
          <Welcome />
        </Row>
        <Row>
          <h1>Annoucements</h1>
          <Announcements />
        </Row>
        <Row>
          <Col align="center">
            <Figure>
              <Figure.Image
                width={300}
                alt="300 as width"
                src={ArmanisArFeinialAuthorsPhoto}
              />
              <Figure.Caption className="figure-caption-custom">
                Armanis Ar-feinial
              </Figure.Caption>
            </Figure>
            </Col>
          </Row>
          <Row>
            <h1>Armanis Ar-feinial's story</h1>
            <AuthorDescription />
          </Row>
      </>
    );
}
