import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';

const Title = ({ title, description, keywords }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />
		</Helmet>
	);
};

Title.defaultProps = {
	title: "Armanis Ar-feinial",
	description: "These are the books of Armanis Ar-feinial",
	keywords: "author, book, grim, grim dark, fantasy, falling, fallen, hedgehog"
};

export default Title;
