import { useState, useEffect } from 'react';
// import {
//   Link,
//   useLoaderData,
//   // useRouteMatch,
// } from 'react-router-dom';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { getBooks } from '../datacalls/bookQuery';
import SearchContainer from '../components/Search';
import Spinner from '../components/Spinner';
import Title from '../components/Title';
import BookCard from '../books/BookCard';

// <Link to={`/${bookId}`}>
// export async function loader() {
//   return getBooks()
// }
// bookId is set to book.slug pretty directly here.
export default function Books(props) {
  // const { isPending, isError, data, error } = useQuery({
  //   queryKey: ['books'],
  //   queryFn: getBooks
  // });
  const { status, data, error } = useQuery({
    queryKey: ['books'],
    queryFn: async () => getBooks
  });
  console.log("This is the books page", data);
  let books = data;
  // if (isPending) {
  //   return <div align="center"><Spinner /></div>;
  // }
  // if (isError) {
  //   return <p>Error from the Books page: {error.message}</p>;
  // }
  return (
    <>
    <Title
      title="Armanis Ar-feinial | Books"
      description="The Books available on Armanis Ar-Feinial's Website"
      keywords="book series, fantasy book series, armanis ar-feinial, fantasy author, grim dark author, the Hedgehog, the Falling"
    />
      <div>
        <h1>Check out the Books Below</h1>
        <Row>
            <p>Or search here . . . </p>
            <p>
              The search will begin to find matches, though it might require up to 5 or more letters.
              Any matches will show up as the title of the book as a link.
            </p>
            <SearchContainer />
        </Row>
        <br />
        <>
        <Row>
          {status === 'pending' ? (
            <Spinner />
          ) : status === 'success' ? (
            books.results.map((book) => {
              const bookId = book.slug;
                return (
                  <Row key={book.pkid}>
                    <BookCard book={book} />
                  </Row>
                );
              })
            ) : status === 'error' ? (
              <p>Error from the all Posts page: {error.message}</p>
            ) : null}
        </Row>
        </>
      </div>
    </>
  );
}


  // {
  // <>
  //   {data.results.map((book) => {
  //     const bookId = book.slug;
  //       return (
  //         <Row key={book.pkid}>
  //           <BookCard book={book} />
  //         </Row>
  //       );
  //   })}
  //   </>
  // }
// {data.results.map((book) => {
//   const bookId = book.slug;
//   return (
//     <article key={book.pkid} style={{ margin: "16px 0 0" }}>
//       <Link to={`/books/${bookId}`}>
//       <p>
//         {book.title}
//         </p>
//       </Link>
//     </article>
//   );
// })}
