
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { getContents, getContent } from '../datacalls/contentQuery';
import Spinner from '../components/Spinner';
// ANNOUNCEMENTS = 'announcements'

export default function Announcements(props) {
    const queryClient = useQueryClient();
    const [selectedContent, setSelectedContent] = useState();
    // from the model ANNOUNCEMENTS = 'announcements'
    const { isPending, isError, data, error } = useQuery({
      queryKey: ['announcements', selectedContent],
      queryFn: () => getContent('announcements'), // using the api slug here
    });
    if (isPending) {
      return <div align="center"><Spinner /></div>;
    }
    if (isError) {
      return <p>Error from the Announcements section: {error.message}</p>;
    }
    let announcements = data;

    return (
      <>
        <ReactMarkdown>{announcements.description}</ReactMarkdown>
      </>
    );
}
