import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getBooks } from '../datacalls/bookQuery';

const getProcessedData = (booksData) => {
    const searchBooks = booksData || [];

    return searchBooks.map((book) => {

      let modifiedBook = { ...book };

      if (modifiedBook.short_blurb && modifiedBook.description) {
        modifiedBook.short_blurb += " " + modifiedBook.description;
        delete modifiedBook.description;
      }

      if (modifiedBook.short_blurb) {
        modifiedBook.short_blurb = modifiedBook.short_blurb.replace(/\r\n\r\n/g, " ");
      }

      return {
        id: modifiedBook.id,
        slug: modifiedBook.slug,
        title: modifiedBook.title,
        short_blurb: modifiedBook.short_blurb,
      };
    });
};


const useFetchAndProcessData = () => {
    const {
        data: booksData,
        isLoading: booksLoading,
        error: booksError,
    } = useQuery({
        queryKey: ['books'],
        queryFn: getBooks,
        select: (books) =>
          books.results.map((book) => ({
            id: book.pkid,
            title: book.title,
            slug: book.slug,
            short_blurb: book.short_blurb,
            description: book.description,
          })),
    });
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      if (booksLoading) {
        setLoading(true);
      } else if (booksError) {
        setError(booksError);
        setLoading(false);
      } else if (booksData) {
        const processedData = getProcessedData(booksData);
        setDocuments(processedData);
        setLoading(false);
        console.log("Processed Data: ", processedData);
      }
    }, [booksLoading, booksError, booksData]);

    return { documents, loading, error };
};

export default useFetchAndProcessData;
