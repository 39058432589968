import { redirect } from 'react-router-dom';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { getBooks, getBook } from './bookQuery';

// The following is used on the single book page to show either the book page or
// 404 page. It is one page, it just makes it a this or that choice.

export function isUrl(checkId) {
  const { isPending, isError, data, error } = useQuery({
    queryKey: ['books'],
    queryFn: getBooks,
  });
  if (isPending) {
    return <p>Root is loading</p>;
  }
  if (isError) {
    return <p>Error from the Root: {error.message}</p>;
  }
  const bookIDList = data.results.map((book) => (book.slug));
  const checkBook = bookIDList.includes(checkId);
  // console.log("The following is checkBook", checkBook);
  return checkBook;
}
