
import { Link } from 'react-router-dom';
import dateFormat, { masks } from 'dateformat';
import ReactMarkdown from 'react-markdown';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { ImBaffled } from 'react-icons/im';
import { FaExternalLinkAlt } from 'react-icons/fa';


const OneBook = ({book}) => {
  // The following is necessary to for prices if they are large
  // I'm leaving it in because it's the kind of thing that might be needed
  // generally in pricing
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (book.amazon_paperback !== "https://nopaperback.com") {
    return (
      <div>
          <Card className={`card-${book.page_background_type}`}>
            <Card.Body>
              <hr />
              <Container>
                <Row className="justify-content-center">
                  <img src={book.cover_photo} className="img-book" />
                </Row>
                <Row>
                  <hr />
                  <h1><strong>{book.title}</strong></h1>
                  <h2><strong>By {book.author}</strong></h2>
                  <p>{book.short_blurb}</p>
                  <ReactMarkdown>
                    {book.description}
                  </ReactMarkdown>
                  <p>The book was released on{' '}{dateFormat(book.release_date, "mmmm dS, yyyy")}</p>
                  <p><strong>
                    <a
                      href={book.amazon_paperback}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Paperback Price: ${numberWithCommas(Number(book.paperback_price))} <FaExternalLinkAlt />
                    </a>
                    </strong>
                  <span>{"  "}|{"  "}</span>
                  <strong>
                    <a
                      href={book.amazon_kindle}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kindle Price: ${numberWithCommas(Number(book.kindle_price))} <FaExternalLinkAlt />
                    </a>
                  </strong></p>
                  <Container align="center">
                    <Row>
                      <Col>
                        <Link
                          style={{ display: "block", margin: "1rem 0" }}
                          to={"/books"}
                        >
                          <button className={`neon-animated-button-${book.page_background_type}`} >Back to the book list</button>
                        </Link>
                      </Col>
                      <Col>
                        <Link
                          style={{ display: "block", margin: "1rem 0" }}
                          to={"/home"}
                        >
                          <button className={`neon-animated-button-${book.page_background_type}`} >Back to the home page</button>
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </div>
      );
  } else {
    return (
      <div>
        <Card className={`card-${book.page_background_type}`}>
          <Card.Body>
            <hr />
            <Container>
              <Row className="justify-content-center">
                <img src={book.cover_photo} className="img-book" />
              </Row>
              <Row>
                <hr />
                <h1><strong>{book.title}</strong></h1>
                <h2><strong>By {book.author}</strong></h2>
                <p>{book.short_blurb}</p>
                <ReactMarkdown>
                  {book.description}
                </ReactMarkdown>
                <p>The book was released on{' '}{dateFormat(book.release_date, "mmmm dS, yyyy")}</p>
                <p><strong>Kindle Price:
                  <a
                    href={book.amazon_kindle}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ${numberWithCommas(Number(book.kindle_price))} <FaExternalLinkAlt />
                  </a>
                </strong></p>
                <Container align="center">
                <Row>
                  <Col>
                    <Link
                      style={{ display: "block", margin: "1rem 0" }}
                      to={"/books"}
                    >
                      <button className={`neon-animated-button-${book.page_background_type}`} >Back to the book list</button>
                    </Link>
                  </Col>
                  <Col>
                    <Link
                      style={{ display: "block", margin: "1rem 0" }}
                      to={"/home"}
                    >
                      <button className={`neon-animated-button-${book.page_background_type}`} >Back to the home page</button>
                    </Link>
                  </Col>
                </Row>
                </Container>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>
    );
  }
};

export default OneBook;
