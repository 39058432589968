
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { getContents, getContent } from '../datacalls/contentQuery';
import Spinner from '../components/Spinner';
// CONTACT_DESCRIPTION = 'contact-description'

export default function ContactText(props) {
    const queryClient = useQueryClient();
    const [selectedContent, setSelectedContent] = useState();
    // from the model CONTACT_DESCRIPTION = 'contact-description'
    const { isPending, isError, data, error } = useQuery({
      queryKey: ['contact', selectedContent],
      queryFn: () => getContent('contact-description'),
    });
    if (isPending) {
      return <div align="center"><Spinner /></div>;
    }
    if (isError) {
      return <p>Error from the Contact section: {error.message}</p>;
    }
    let contacttext = data;

    return (
      <>
        <ReactMarkdown>{contacttext.description}</ReactMarkdown>
      </>
    );
}
