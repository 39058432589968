import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  RouterProvider,
  Route,
  Switch,
} from 'react-router-dom';
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import ReactMarkdown from 'react-markdown';

import './styles/main.scss';

import Root from './routes/Root';
import ErrorNote from './routes/ErrorNote';
import Home from './routes/Home';
import About from './routes/About';
import Books from './routes/Books';
import Book from './routes/Book';
import Contact from './routes/Contact';
import Confirmation from './routes/Confirmation';
import { getBooks } from './datacalls/bookQuery';

// try answer from:
// https://stackoverflow.com/questions/74737857/react-router-v6-4-5-redirect-on-page-load
// {
//   path: "otherprojects",
//   element: <OtherProjects />,
//   errorElement: <div>Other projects route error</div>,
// },
// {
//   index: true,
//   element: <Navigate to="/home" replace />,
//   errorElement: <div>Index route error element</div>,
// },

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 10,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
      children: [
        { errorElement: <ErrorNote />,
          children: [
            {
              index: true,
              element: <Navigate to="/home" replace />,
            },
            {
              path: "home",
              element: <Home />,
            },
            {
              path: "about",
              element: <About />,
            },
            {
              path: "contact",
              element: <Contact />,
            },
            {
              path: "confirmation",
              element: <Confirmation />,
            },
            {
              path: "books",
              element: <Books />,
            },
            {
              children: [
                {
                  path: "books/:bookId",
                  element: <Book />,
                },
                {
                  path: "books/*",
                },
              ],
            },
            {
              path: "*",
              element: <ErrorNote />,
            }
          ],
        },
      ],
    },
]);

// errorElement: <div>Single book route error</div>,
// bookId is equal to book.slug from the backend.
// It does not need to be mapped or set to book.slug here.

// The path with the star was the only decent way to get the
// Error Page to work properly.

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  </>
);
