
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Spinner from '../components/Spinner';
import Title from '../components/Title';
import ContactForm from '../components/ContactForm';
import ContactText from '../sitecontents/ContactText';


export default function Contact() {

    const apiUrl = process.env.BACK_TEST_URL;

    return (
      <>
      <Title
        title="Armanis Ar-feinial | Contact"
        description="The error page for the Armanis Ar-Feinial's website"
        keywords="contact form, contact page, armanis ar-feinial, fantasy author, grim dark author, the Hedgehog, the Falling"
      />
        <Container>
          <Row></Row>
            <h1>Use the following form to make contact</h1>
            <ContactText />
            <ContactForm endpoint="anon-bard/v1/" />
          <Row></Row>
        </Container>
      </>
    );
}
