import axios from 'axios';


export async function getBooks() {
    const apiUrl = process.env.BACK_TEST_URL
    const { data } = await axios.get(`${apiUrl}/bookapi/`);
    let books = data;
    // console.log("This is books from getBooks", books);
    return books;
}

export async function getBook(selectedBook) {
    const apiUrl = process.env.BACK_TEST_URL;
    const { data } = await axios.get(`${apiUrl}/bookapi/${selectedBook}`);
    // console.log("This is selectedBook in getBook", selectedBook);
    let book = data;
    if (!selectedBook) {
      // console.log("This is the book in getBook IF the book is set to null", book);
      return null;
    } else {
      // console.log("This is the book in getBook if not null", book);
      return book;
  }
}
