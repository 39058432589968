import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { BiPaperPlane, BiMailSend } from 'react-icons/bi';
import { GiRobotGolem } from 'react-icons/gi';
import Title from '../components/Title';


export default function Confirmation() {

  return (
    <>
    <Title
      title="Armanis Ar-feinial | Confirmation"
      description="The confirmation for the contact form"
      keywords="confirmation page, contact form, armanis ar-feinial, fantasy author, grim dark author, the Hedgehog, the Falling"
    />
      <Card className="card-blue-green">
        <Card.Title>
          <h1>Your message has been sent.</h1>
          <h2>Thank you!</h2>
        </Card.Title>
        <Card.Body>
          <Container align="center">
            <Row>
              <Col>
                <BiMailSend className="blue-icon" />
                <GiRobotGolem className="green-icon" />
                <BiPaperPlane className="blue-icon" />
              </Col>
            </Row>
          </Container>
          <br />
          <hr />
          <br />
          <Row align="center">
            <Col>
              <Link to="/">
                <button className="neon-animated-button-blue-green">Home Page</button>
              </Link>
            </Col>
            <Col>
              <Link to="/books">
                <button className="neon-animated-button-blue-green">Books Page</button>
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
