import React, {
  useEffect,
  useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useLoaderData } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { FaHeartBroken, FaSadTear } from 'react-icons/fa';
import { ImBaffled } from 'react-icons/im';
import { BiConfused, BiBot } from 'react-icons/bi';
import { TbSwords } from 'react-icons/tb';
import { getBooks, getBook } from '../datacalls/bookQuery';
import { isUrl } from '../datacalls/urlLoaders';
import Spinner from '../components/Spinner';
import Title from '../components/Title';
import OneBook from '../books/OneBook';


// The bookId is here is attached via useParams. It is set in links and books as well.
export default function Book(props) {
  // useParams is what attaches this to the correct page.
  const { bookId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const checkBookUrl = isUrl(bookId);
  if (checkBookUrl == true) {
    const { isPending, isError, data, error } = useQuery({
      queryKey: ['book', bookId],
      queryFn: () => getBook(bookId),
    });
    if (isPending) {
      return <div align="center"><Spinner /></div>;
    }
    if (isError) {
      return <p>Error from the Books page: {error.message}</p>;
    }
    // console.info(data);
    let book = data;

    return (
      <>
      <Title
        title="Armanis Ar-feinial | Book"
        description="One of the Books available on Armanis Ar-Feinial's Website"
        keywords="book series, fantasy book series, fantasy author, grim dark author, the Hedgehog, the Falling"
      />
        <OneBook book={book} />
      </>
    );
  } else if (checkBookUrl == false) {
    return (
      <>
      <Title
        title="Armanis Ar-feinial | Error"
        description="An error page from Armanis Ar-Feinial's Website"
        keywords="book series, fantasy book series, armanis ar-feinial, fantasy author, grim dark author, the Hedgehog, the Falling"
      />
        <Card className="card-black-blue">
          <Card.Title>
            <Row>
              <h1>Oh No! An Error has occured . . .</h1>
              <p align="center">This is unexpected. This url does not exist.</p>
            </Row>
          </Card.Title>
          <Card.Body>
            <Container align="center">
              <Row>
                <Col>
                  <BiBot className="green-icon" />
                  <TbSwords className="blue-icon" />
                  <BiConfused className="green-icon" />
                </Col>
              </Row>
            </Container>
            <br />
            <hr />
            <br />
            <Row align="center">
              <Col>
                <Link to="/">
                  <button className="neon-animated-button-black-blue">Home Page</button>
                </Link>
              </Col>
              <Col>
                <Link to="/books">
                  <button className="neon-animated-button-black-blue">Book Page</button>
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
};
